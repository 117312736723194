@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../../styles/vars.scss' as vars;

.options-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  scrollbar-width: auto;
  overflow-x: scroll;

  @media (max-width: vars.$TabletSize) {
    gap: 4px;
    padding-bottom: 10px;
  }

  .button {
    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }

    @media (max-width: vars.$TabletSize) {
      margin: util.rem(5px);
    }
  }
}

.submit-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: sizes.$space-16;

  @include util.media(sizes.$mq-mobile) {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.button-variant2 {
  cursor: pointer;

  @include util.media(sizes.$mq-mobile) {
    margin-top: 0;
  }

  &:hover {
    .typography {
      color: colors.$ce-purple;
    }
  }
}
