.wam-color-drawer {
  button {
    max-width: none;
  }
}

.text-option-drawer-container > div:nth-child(2) > div:first-child > div:first-child {
  scrollbar-width: auto;
}

.size-drawer {
  z-index: 10;
}
