@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '../../styles/vars.scss' as vars;

.card-face {
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1300px) and (max-width: 1366px) {
    width: 860px;
  }

  @media (min-width: 1179px) and (max-width: 1300px) {
    width: 680px;
  }

  .beta-label-container {
    width: 100%;
    padding: 4px 0;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .beta-label {
      width: fit-content;
      font-size: 0.8em;
      padding: 4px 15px;
      margin: 0 auto;
      background: colors.$yellow;
      border-color: colors.$yellow;
    }
  }

  .instructions {
    position: absolute;
    width: 30%;
    max-width: 240px;
    top: 0;
    right: util.rem(-64px);
    text-align: center;
    padding: 0 sizes.$space-16;
    @media (max-width: vars.$LargerDesktop) {
      position: static;
      width: 100%;
      max-width: unset;
      padding: 0 4vw;
      .instructions-text {
        font-size: util.rem(16px);
      }
    }
  }

  .landscape-instructions {
    height: 4vh;
    width: 100%;
    margin: 0.5em 0;
    text-align: center;
    @media (max-width: vars.$Desktop) {
      .instructions-text {
        font-size: util.rem(16px);
      }
    }
  }

  .instructions__hidden {
    opacity: 0;
    transition: all 0.5s ease-in;
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    :global(.canvas-container) {
      margin: 0 auto;
    }
  }

  .canvas-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .canvas-label {
    font-family: 'BeamNewHMK-114', sans-serif;
    font-size: util.rem(13px);
    padding: sizes.$space-8 0;
    line-height: util.rem(20px);
    text-align: left;
    color: #293035;
    letter-spacing: 0.4px;
    // margin-left: 15vw;
    @media (max-width: vars.$Desktop) {
      margin: 0;
    }
  }
}
